.app {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.controls {
  width: 100%;
  flex-grow: 0.2;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.app .timer:nth-child(1) {
  transform: rotateZ(180deg);
}

.timer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: whitesmoke;
  width: 100%;
  font-weight: bold;
  user-select: none;
  font-size: 20vmin;
}

.timer.active {
  background-color: orange;
  color: white;
}